import Repo from './_repo.js'

var indexingPromise = null
let blockingTimer = null

var cardChecklistsCache = {}
const checklistCache = {}
const checkItemCache = {}

export default class extends Repo {
  static create(cardId, data) {
    return $tpu.r.data.restFetch(
      `/cards/${cardId}/checklists`,
      'POST',
      data).then((result) => {
        if (result) { // Success
          const checklists = this.findAllByCardId(cardId)
          checklists.push(result)
          checklistCache[result.id] = result
        }

        return result
      })
  }

  static update(checklistId, updatedData) {
    return $tpu.r.data.restFetch(
      `/checklists/${checklistId}`,
      'PUT',
      updatedData).then((result) => {
        if (result) { // Success
          const checklist = this.find(checklistId)
          Object.assign(checklist, updatedData)
        }

        return result
      })
  }

  static destroy(checklistId, cardId) {
    return $tpu.r.data.restFetch(
      `/checklists/${checklistId}`,
      'DELETE').then((result) => {
        if (result) { // Success
          const cache = cardChecklistsCache[cardId] || { lastRefreshedAt: 0 }
          cache.data = cache.data.filter((checklist) => {
            return checklist.id != checklistId
          })
        }

        return result
      })
  }

  static _fetchChecklists(cardId) {
    const currentTimestamp = new Date().getTime()
    return $tpu.r.data.restFetch(`/cards/${cardId}/checklists`, 'GET', {}).then((result) => {
      var fetchedChecklists = null

      // Make sure failed request doesn't override our cache.
      GLib.type.ifObject(result, (checklists) => {
        checklists.forEach((checklist) => {
          checklistCache[checklist.id] = checklist

          checklist.checkItems = checklist.checkItems.sort((a, b) => {
            return a.pos - b.pos
          })

          checklist.checkItems.forEach((checkItem) => {
            checkItemCache[checkItem.id] = checkItem
          })
        })

        cardChecklistsCache[cardId] = {
          data: checklists,
          lastRefreshedAt: currentTimestamp
        }

        fetchedChecklists = checklists
      })

      return fetchedChecklists
    }).catch(() => {
      // We often get 429 initially because Trello servers will get flooded when the board is
      // launched the first time. Returning null here is a way to gracefully continue.
      return null
    })
  }

  static _all(t) {
    if (this.isInTrello()) {
      const updatedCardIds = []
      $tpu.r.cards.findAll().forEach((card) => {
        if (!card.shapeUp.scopeCardId) { // Not a task card, not interested in its checklists.
          return
        }
        const cardId = card.id
        const cache = cardChecklistsCache[cardId] || { lastRefreshedAt: 0, data: [] }

        if (cache.lastRefreshedAt <= card.shapeUp.updatedAt) {
          updatedCardIds.push(cardId)
        }
      })

      return Promise.all(updatedCardIds.map((cardId) => {
        return this._fetchChecklists(cardId)
      }))
    }

    return this._dummyData()
  }

  static async _registryPromise(t) {
    const checklistRegistry = await this._all(t)

    this._delayCacheUpdate()

    return checklistRegistry
  }

  static _delayCacheUpdate() {
    clearTimeout(blockingTimer)
    // Let the promise be reused by other requests that were made within X seconds.
    blockingTimer = setTimeout(() => {
      indexingPromise = null
    }, 1000)
  }

  static registry(t) {
    if (indexingPromise) {
      // console.debug("Reusing checklist indexing promise...")
      return indexingPromise
    }

    // console.debug("Fetching new indexed checklists...")

    // indexingPromise = this._all(t).then(() => {
    //   indexingPromise = null
    //   return checklistCache
    // })
    indexingPromise = this._registryPromise(t)
    return indexingPromise
  }

  static cacheByCardId(cardId) {
    return cardChecklistsCache[cardId] || {}
  }

  static registerCard(card) {
    return cardChecklistsCache[card.id] = {
      data: [],
      lastRefreshedAt: card.shapeUp.updatedAt
    }
  }

  static findAllByCardId(cardId) {
    // Initially `cardChecklistsCache[cardId]` may not contain anything but after everything
    // has been loaded, it should be quite reliable.
    return this.cacheByCardId(cardId).data
  }

  static find(checklistId) {
    return checklistCache[checklistId]
  }

  // Protected method. Use $tpu.r.checkItems.find() instead
  static cachedCheckItem(checkItemId) {
    return checkItemCache[checkItemId]
  }

  static addItem(checklistId, checkItem) {
    const checklist = this.find(checklistId)
    checklist.checkItems.push(checkItem)
    checkItemCache[checkItem.id] = checkItem
  }

  static removeItem(checklistId, checkItemId) {
    const checklist = this.find(checklistId)
    checklist.checkItems = checklist.checkItems.filter(function(checkItem) {
      return checkItem.id != checkItemId;
    })
    delete checkItemCache[checkItemId]
  }

  static _dummyData() {
    return new Promise((resolve, reject) => {
      cardChecklistsCache = {
        task1: {
          data: [],
          lastRefreshedAt: 1682664929351
        },
        task2: {
          data: [
            {
              id: "checklist1",
              name: "Checklist One",
              checkItems: [
                { id: 'checkitem1', name: 'Item One Use cards dateLastActivity to check when a cards data (e.g. checklists) is stale', state: 'incomplete' },
                { id: 'checkitem2', name: 'Item Two', state: 'complete' }
              ],
            },
            {
              id: "checklist2",
              name: "Checklist Two",
              checkItems: [
                { id: 'checkitem3', name: 'Item Three', state: 'incomplete' },
              ]
            }
          ],
          lastRefreshedAt: 1682664939373
        },
        task3: {
          data: [],
          lastRefreshedAt: 1682664929351
        },
        task4: {
          data: [],
          lastRefreshedAt: 1682664929351
        },
        task5: {
          data: [],
          lastRefreshedAt: 1682664929351
        },
        task6: {
          data: [],
          lastRefreshedAt: 1682664929351
        },
        task7: {
          data: [],
          lastRefreshedAt: 1682664929351
        },
        task8: {
          data: [],
          lastRefreshedAt: 1682664929351
        },
        task9: {
          data: [],
          lastRefreshedAt: 1682664929351
        },
      }
      Object.entries(cardChecklistsCache).forEach(([_cardId, cache]) => {
        cache.data.forEach((checklist) => {
          checklistCache[checklist.id] = checklist

          checklist.checkItems.forEach((checkItem) => {
            checkItemCache[checkItem.id] = checkItem
          })
        })
      })

      resolve()
    })
  }
}
